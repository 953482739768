import React from 'react';
import { Box } from '@mui/material';
import bgImage from './images/bg_1.webp';
import HeaderButtons from './HeaderButtons';
import SectionRendererTree from './SectionRendererTree';
import SectionRendererDetails from './SectionRendererDetails';
import SplitPane from 'react-split-pane';

const SectionRenderer = ({
  filteredData,
  onToggle,
  selectedField,
  setSelectedField,
  selectedNode,
  openInNewTab,
  renderFieldContent,
  treeType,
  setTreeType
}) => {
  console.log('SectionRenderer rendered with treeType:', treeType);

  return (
    <Box style={{
      height: '1000px', // Fixed height for the entire container
      width: '100%',
      //backgroundImage: `url(${bgImage})`,
      backgroundColor: '#DDEEFF', 
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      padding: '20px',
      boxSizing: 'border-box',
      border: '1px solid grey' // Add border here
    }}>
      <HeaderButtons treeType={treeType} setTreeType={setTreeType} />

      {/* ========== MAIN BOX ================================================ */}
      <Box style={{ height: '900px', border: '1px solid darkgrey', position: 'relative' }}>
        {/* ========== SPLIT PANE ================================================ */}
        <SplitPane
          split="horizontal"
          minSize={50}
          defaultSize="30%"
          style={{ height: '100%' }} // Ensure the SplitPane fits within the parent Box
          resizerStyle={{
            background: '#000', // Farbe der Trennlinie
            opacity: 0.2,
            cursor: 'row-resize', // Cursor für horizontale Trennung
            height: '5px', // Höhe der Trennlinie
            zIndex: 1,
          }}
        >
          {/* ---------- TREE ---------------------------------------- */}
          <Box style={{ backgroundColor: '#FFFFFF', width: '100%', height: '96%', border: '2px solid lightgrey', display: 'flex', flexDirection: 'column' }}>
            <SectionRendererTree 
              filteredData={filteredData} 
              onToggle={onToggle} 
              treeType={treeType} // Add this line to pass treeType
              style={{ flex: 1 }}
            />
          </Box>
          {/* ---------- DETAIL PANEL ---------------------------------------- */}
          <Box style={{ width: '100%', height: '100%' }}>
		    <SectionRendererDetails 
              selectedField={selectedField}
              setSelectedField={setSelectedField}
              selectedNode={selectedNode}
              openInNewTab={openInNewTab}
              renderFieldContent={renderFieldContent}
			  treeType={treeType} 
            />
			
          </Box>
        </SplitPane>
      </Box>
    </Box>
  );
};



export default SectionRenderer;

import React from 'react';
import { Box } from '@mui/material';
import { Treebeard, decorators } from 'react-treebeard';
import treeStyles from './treebeardStyles';
import NodeHeader from './NodeHeader';

const SectionRendererTree = ({ filteredData, onToggle, treeType }) => {
  // console.log('SectionRendererTree rendered with treeType:', treeType);
  return (
    <Box style={{
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      padding: '10px',
      borderRadius: '1px',
      overflow: 'auto'
    }}>
      <Treebeard 
        data={filteredData} 
        onToggle={onToggle} 
        style={treeStyles} 
        decorators={{ ...decorators, Header: (props) => <NodeHeader {...props} onToggle={onToggle} treeType={treeType} /> }} 
      />
    </Box>
  );
};

export default SectionRendererTree;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import TestPage from './components/TestPage'; // Import TestPage
import Section from './components/Section';
import Advertisement from './components/Advertisement';
import Footer from './components/Footer';
import Forum from './components/Forum';
import NoticeDialog from './components/NoticeDialog';
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL || 'https://api.open-source.org/api';
const API_SITE_LOADED = `${API_URL}/siteLoaded`;
const API_NEW_VISIT = `${API_URL}/newvisit`; // Neuer Endpunkt für das Hochzählen des Zählers

const App = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [version, setVersion] = useState('V.0.0.0');
  const [currentSection, setCurrentSection] = useState('dashboard');
  const [treeType, setTreeType] = useState('projects'); // Added

  useEffect(() => {
    console.log('open-source.org app loaded');
    console.log('Backend URL:', process.env.REACT_APP_BACKEND_URL);

    fetch('/version.txt')
      .then(response => response.text())
      .then(text => {
        setVersion(`V.0.1.${text.trim()}`);
      })
      .catch(error => console.error('Error fetching version:', error));
  }, []);

  useEffect(() => {
    const notifySiteLoaded = async () => {
      try {
        await axios.get(API_SITE_LOADED);
        console.log('Site loaded event sent to server');
      } catch (error) {
        console.error('Error notifying site load:', error);
      }
    };

    const incrementVisitCounter = async () => {
      try {
        const response = await axios.get(API_NEW_VISIT);
        console.log('Visit counted:', response.data);
      } catch (error) {
        console.error('Error incrementing visit counter:', error);
      }
    };

    notifySiteLoaded();
    incrementVisitCounter(); // Zähler beim Laden der Seite erhöhen
  }, []);

  const handleSearch = (query) => {
    console.log('handleSearch called with query:', query);
    setSearchQuery(query);
    return query !== ""; // Placeholder, implement the actual search logic
  };

  useEffect(() => {
    console.log('Current treeType:', treeType);
  }, [treeType]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <Header version={version} onSearch={handleSearch} setCurrentSection={setCurrentSection} />
            <Section searchQuery={searchQuery} currentSection={currentSection} setCurrentSection={setCurrentSection} treeType={treeType} setTreeType={setTreeType} />
            <Advertisement />
            <Footer />
            <NoticeDialog />
          </>
        } />
        <Route path="/forum" element={
          <>
            <Header version={version} onSearch={handleSearch} setCurrentSection={setCurrentSection} />
            <Forum />
            <Footer />
            <NoticeDialog />
          </>
        } />
        <Route path="/test" element={<TestPage />} /> {/* New route for Test */}
      </Routes>
    </Router>
  );
};

export default App;

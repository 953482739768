// Advertisement_2.js

import React from 'react';
import './Advertisement.css'; // Importiere die CSS-Datei für Animationen

// Beispielbilder und Links für die Werbung
const ads = [
  { src: '/advertisement/add1.jpg', url: 'https://a-to-mic.net' },
  { src: '/advertisement/add2.jpg', url: 'https://www.a-to-mic.net/DEMO/index.php' },
  { src: '/advertisement/add3.jpg', url: 'https://www.youtube.com/watch?v=jNbjl3lks9w' },
];

const Advertisement = () => {
  const [currentAd, setCurrentAd] = React.useState(0);
  const [imageDimensions, setImageDimensions] = React.useState({ width: 0, height: 0 });
  const [showAd, setShowAd] = React.useState(false); // Zustand für das Anzeigen des Ads
  const adImageRef = React.useRef(null);

  React.useEffect(() => {
    // Verzögerung von 3 Sekunden, bevor das erste Bild eingeblendet wird
    const timer = setTimeout(() => setShowAd(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    // Interval für das Bildwechseln und Anzeigen des nächsten Bildes
    const adInterval = setInterval(() => {
      setCurrentAd((prevAd) => (prevAd + 1) % ads.length);
      setShowAd(false);
      const showAdTimer = setTimeout(() => setShowAd(true), 5000); // 0.5 Sekunde für die Einblend-Animation
      return () => clearTimeout(showAdTimer);
    }, 10000); // 5 Sekunden sichtbar + 5 Sekunden Pause

    return () => clearInterval(adInterval);
  }, []);

  React.useEffect(() => {
    // Dimensionen des Bildes nach dem ersten Laden berechnen
    if (adImageRef.current) {
      const { naturalWidth, naturalHeight } = adImageRef.current;
      const headerHeight = 150; // Höhe des Headers, anpassen nach Bedarf
      const adHeight = headerHeight - 40; // Höhe des Bildes um 60px reduzieren
      setImageDimensions({
        width: (adHeight / naturalHeight) * naturalWidth,
        height: adHeight,
      });
    }
  }, [currentAd]);

  const handleAdClick = () => {
    console.log(`Ad clicked: ${ads[currentAd].url}`);
    window.open(ads[currentAd].url, '_blank');
  };

  return (
    <div className={`advertisement-container ${showAd ? 'fade-in' : ''}`}>
      <img
        ref={adImageRef}
        src={ads[currentAd].src}
        alt="Advertisement"
        className="advertisement-image"
        onClick={handleAdClick}
        onError={() => console.error(`Failed to load image: ${ads[currentAd].src}`)}
      />
    </div>
  );
};

export default Advertisement;

export function convertPathToKey(path) {
  return path.replace(/[\/()]/g, '_');
}

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const parseData = (data, maxItems) => {
  let count = 0;

//  if(count == 0) {
//    console.log('In treeUtils: Data:', JSON.stringify(data, null, 2));
//  }
  
  const traverse = (node, level = 0) => {
    if (count >= maxItems) return null;
    count++;
    // console.log(`Processing node at level ${level}: ${node.name}`);
    // console.log(`Node structure at level ${level}: ${JSON.stringify(node, null, 2)}`);

    // Set the toggled property to false for all levels except the root and level 1
    const toggled = level <= 1;

    // Check if the node has children and log the children
    if (Array.isArray(node.children)) {
      // console.log(`Node ${node.name} at level ${level} has children array with length: ${node.children.length}`);
      if (node.children.length > 0) {
        //console.log(`Node ${node.name} children details at level ${level}: ${JSON.stringify(node.children, null, 2)}`);

        // Recursively process each child node
        const processedChildren = node.children.map(child => traverse(child, level + 1)).filter(child => child !== null);
        //console.log(`Processed children for node ${node.name} at level ${level}: ${JSON.stringify(processedChildren, null, 2)}`);

        return {
          ...node,
          toggled: toggled,
          children: processedChildren
        };
      } else {
        // console.log(`Node ${node.name} has an empty children array at level ${level}`);
        return {
          ...node,
          toggled: toggled,
          children: []
        };
      }
    } else {
      // console.log(`Node ${node.name} does not have a children array at level ${level}`);
      return {
        ...node,
        toggled: toggled,
        children: [] // Ensure every node has a children property
      };
    }
  };

  if (Array.isArray(data.children) && data.children.length > 0) {
    const parsedChildren = data.children.map(item => traverse(item)).filter(item => item !== null);
    // console.log('Parsed children:', JSON.stringify(parsedChildren, null, 2));
    return { ...data, children: parsedChildren, toggled: true };
  } else {
    // console.error("Data does not have children property or children array is empty");
    return { ...data, children: [], toggled: true };
  }
};
// Beispielkomponente: Forum.js
import React from 'react';

const Forum = () => {
    return (
        <div>
            <h1>Forum</h1>
            <p>Under Construction</p>
        </div>
    );
};



export default Forum;

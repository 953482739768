// Section.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SectionRenderer from './SectionRenderer';
import { deepCopy, parseData } from './treeUtils';

const API_URL = process.env.REACT_APP_BACKEND_URL || 'https://api.open-source.org/api';
const API_GENERIC = (type) => `${API_URL}/getDocuments/${type}`;

const Section = ({ searchQuery, currentSection, setCurrentSection, treeType, setTreeType }) => {
  const [data, setData] = useState({ name: 'Root', children: [] });
  const [filteredData, setFilteredData] = useState({ name: 'Root', children: [] });
  const [cursor, setCursor] = useState(null);
  const [selectedField, setSelectedField] = useState('desc');
  const [selectedNode, setSelectedNode] = useState(null);
  const [iframeError, setIframeError] = useState(false);
  const [embeddable, setEmbeddable] = useState(true);
  const iframeRef = useRef(null);
  const [timer, setTimer] = useState(null);

  const [initialized, setInitialized] = useState(false);

  const findNodeByName = (node, name) => {
    if (node.name.toLowerCase() === name.toLowerCase()) {
      return node;
    }
    if (node.children) {
      for (const child of node.children) {
        const result = findNodeByName(child, name);
        if (result) return result;
      }
    }
    return null;
  };


  // Set the initial section only once
  useEffect(() => {
    if (!initialized) {
      setCurrentSection('dashboard');
      setInitialized(true);
    }
  }, [initialized, setCurrentSection]);

  useEffect(() => {
    fetchData(treeType);
  }, [treeType]);

  const fetchData = async (type) => {
    const endpoint = API_GENERIC(type);

    try {
      const response = await axios.get(endpoint);
      const rawData = response.data;
      const parsedData = parseData(rawData, 100);
      setData(parsedData);
      setFilteredData(parsedData);
    } catch (error) {
      console.error(`Error fetching ${type}:`, error);
    }
  };


  useEffect(() => {
    if (searchQuery) {
      const foundNode = findNodeByName(data, searchQuery);
      if (foundNode) {
        setSelectedNode(foundNode);
	    console.log('====================== foundNode.desc: ', foundNode.desc);
      } else {
        setSelectedNode(null); // Wenn kein Knoten gefunden wird
      }
    } else {
      setSelectedNode(null); // Wenn keine Suchanfrage vorhanden ist
    }
  }, [searchQuery, data]);


  const selectNode = (node, query) => {
    let found = false;
    // Unset `active` state for all nodes first
    unsetAllNodesActive(node);
    if (node.name.toLowerCase().includes(query.toLowerCase())) {
      node.active = true;
      node.toggled = true;
      found = true;
      // Store the active node to update the state later
      node.activeNode = node;
    }
    if (node.children) {
      for (let child of node.children) {
        if (selectNode(child, query)) {
          node.toggled = true;
          found = true;
        }
      }
    }
    return found;
  };

  const unsetAllNodesActive = (node) => {
    node.active = false;
    if (node.children) {
      node.children.forEach(unsetAllNodesActive);
    }
  };

  const updateDetailPane = (node) => {
    // Directly update the selected node
    setSelectedNode(node);
    setCursor(node);
    // Simulate a click or update in the SectionRenderer or another component if needed
    if (node) {
      console.log(`Updating detail pane with node: ${node.name}`);
      // Example: Trigger a refresh or update in a child component
      // You might need to add functionality in SectionRenderer or handle this differently
    }
  };

  const onToggle = (node, toggled) => {
    const isRoot = node.name === filteredData.name;
    if (cursor) {
      cursor.active = false;
    }
    node.active = true;

    const expandAllExceptLeaves = (currentNode) => {
      if (currentNode.children && currentNode.children.length > 0) {
        const allChildrenAreLeaves = currentNode.children.every(
          (child) => !child.children || child.children.length === 0
        );

        if (allChildrenAreLeaves) {
          currentNode.toggled = false;
        } else {
          currentNode.toggled = true;
          currentNode.children.forEach((child) => expandAllExceptLeaves(child));
        }
      } else {
        currentNode.toggled = false;
      }
    };

    if (isRoot) {
      expandAllExceptLeaves(node);
    } else {
      node.toggled = toggled;
    }

    setCursor(node);
    setSelectedNode(node);
    setFilteredData({ ...filteredData });
  };

  const openInNewTab = (url) => {
    const tabName = `tab-${new URL(url).hostname.replace(/[^a-z0-9]/gi, '_').toLowerCase()}`;
    const existingTab = window.open('', tabName);

    try {
      if (existingTab.location.href === 'about:blank') {
        existingTab.location.href = url;
      }
    } catch (error) {
      // Ignore cross-origin errors
    }

    existingTab.focus();
  };

  const markUrlAsNonEmbeddable = async (url) => {
    try {
      const response = await fetch(`${API_URL}/mark-non-embeddable`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      });

      if (!response.ok) {
        console.error(`Failed to mark URL as non-embeddable: ${response.statusText}`);
        return;
      }

      await response.json();
    } catch (error) {
      console.error('Error marking URL as non-embeddable:', error);
    }
  };

  const handleMarkNonEmbeddable = async () => {
    if (selectedNode && selectedNode.fields?.url) {
      try {
        await axios.post(`${API_URL}/mark-non-embeddable`, { url: selectedNode.fields.url });
      } catch (error) {
        console.error('Error marking URL as non-embeddable:', error);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'e' && selectedNode && selectedNode.fields?.url) {
        markUrlAsNonEmbeddable(selectedNode.fields.url);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedNode]);

  // Check the current section to render either the tree or the iframe
  if (currentSection === 'dashboard' || currentSection === '') {
    return (
      <SectionRenderer
        filteredData={filteredData}
        onToggle={onToggle}
        selectedField={selectedField}
        setSelectedField={setSelectedField}
        selectedNode={selectedNode}
        openInNewTab={openInNewTab}
        treeType={treeType}
        setTreeType={setTreeType}
      />
    );
  }

  return (
    <iframe
      src={currentSection}
      style={{ width: '100%', height: '80vh', border: 'none' }}
      title="External Content"
    />
  );
};

export default Section;


import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';

const Footer = () => {
  const [footerContent, setFooterContent] = useState('');

  useEffect(() => {
    axios.get('/footer.txt')
      .then(response => {
        setFooterContent(response.data);
      })
      .catch(error => {
        console.error('Error fetching footer content:', error);
      });
  }, []);

  return (
    <Box style={{
      backgroundColor: 'lightgreen',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px', // Smaller font size
      whiteSpace: 'pre-line',
      padding: '20px',
      boxSizing: 'border-box'
    }}>
      {footerContent}
    </Box>
  );
};

export default Footer;

import React from 'react';
import { Box, Button } from '@mui/material';

import ProjectsIcon from '@mui/icons-material/Folder';
import LicensesIcon from '@mui/icons-material/Assignment';
import DocumentationIcon from '@mui/icons-material/Description';
import ToolsIcon from '@mui/icons-material/Build';
import TutorialsIcon from '@mui/icons-material/School';
import SourcesIcon from '@mui/icons-material/Source';
import ForumsIcon from '@mui/icons-material/Forum';

const getButtonStyle = (active) => ({
  backgroundColor: active ? '#1976d2' : '#f0f0f0',
  color: active ? '#fff' : '#000',
  fontSize: '12px',
  border: '2px solid',
  borderColor: '#aaaaaa',
  '&:hover': {
    backgroundColor: active ? '#115293' : '#e0e0e0',
  },
});

const getIconStyle = (active) => ({
  color: active ? '#fff' : '#1976d2',
});

const HeaderButtons = ({ treeType, setTreeType }) => {
  const handleButtonClick = (type) => {
    console.log('Button clicked:', type);
    setTreeType(type);
  };

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: '10px',
        gap: '10px',
      }}
    >
      <Button
        onClick={() => handleButtonClick('projects')}
        sx={getButtonStyle(treeType === 'projects')}
        startIcon={<ProjectsIcon sx={getIconStyle(treeType === 'projects')} />}
      >
        Projects
      </Button>
      
      <Button
        onClick={() => handleButtonClick('licenses')}
        sx={getButtonStyle(treeType === 'licenses')}
        startIcon={<LicensesIcon sx={getIconStyle(treeType === 'licenses')} />}
      >
        Licenses
      </Button>
      
      <Button
        onClick={() => handleButtonClick('documentation')}
        sx={getButtonStyle(treeType === 'documentation')}
        startIcon={<DocumentationIcon sx={getIconStyle(treeType === 'documentation')} />}
      >
        Documentation
      </Button>
      
      <Button
        onClick={() => handleButtonClick('tools')}
        sx={getButtonStyle(treeType === 'tools')}
        startIcon={<ToolsIcon sx={getIconStyle(treeType === 'tools')} />}
      >
        Tools
      </Button>
      
      <Button
        onClick={() => handleButtonClick('tutorials')}
        sx={getButtonStyle(treeType === 'tutorials')}
        startIcon={<TutorialsIcon sx={getIconStyle(treeType === 'tutorials')} />}
      >
        Tutorials
      </Button>
      
      <Button
        onClick={() => handleButtonClick('sources')}
        sx={getButtonStyle(treeType === 'sources')}
        startIcon={<SourcesIcon sx={getIconStyle(treeType === 'sources')} />}
      >
        Sources
      </Button>
      
      <Button
        onClick={() => handleButtonClick('forums')}
        sx={getButtonStyle(treeType === 'forums')}
        startIcon={<ForumsIcon sx={getIconStyle(treeType === 'forums')} />}
      >
        Forums
      </Button>
    </Box>
  );
};

export default HeaderButtons;

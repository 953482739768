import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';
import { convertPathToKey } from './treeUtils';

const API_URL = process.env.REACT_APP_BACKEND_URL || 'https://open-source.org/api';
const API_DESCRIPTION = (key) => `${API_URL}/getDescription?path=${key}`;

const SectionRendererDetails = ({ selectedField, setSelectedField, selectedNode, openInNewTab, treeType }) => {
  const [description, setDescription] = useState('');
  const [apiContent, setApiContent] = useState('');
  const [useApiContent, setUseApiContent] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');
  const [embeddable, setEmbeddable] = useState(true);
  const [iframeError, setIframeError] = useState(false);

  useEffect(() => {
    console.log('Effect triggered: selectedNode:', selectedNode, 'selectedField:', selectedField);

    if (selectedNode && selectedField === 'desc') {
      if (selectedNode.desc && selectedNode.desc.startsWith('html:')) {
        const path = selectedNode.desc.substring(5).trim();
        fetchDescription(path);
      } else {
        setDescription(selectedNode.desc || 'No description available');
        setApiContent('');
        setUseApiContent(false);
      }
    }
  }, [selectedNode, selectedField]);

  async function fetchDescription(path) {
    const key = convertPathToKey(path);
    const apiUrl = API_DESCRIPTION(key);
    try {
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        const description = response.data;
        if (description !== '-' && description.trim() !== '') {
          setApiContent(description);
          setUseApiContent(true);
        } else {
          setApiContent('');
          setUseApiContent(false);
          setDescription(selectedNode.desc || 'No description available');
        }
      } else {
        console.error(`Failed to fetch description: ${response.statusText}`);
        setUseApiContent(false);
        setDescription(selectedNode.desc || 'No description available');
      }
    } catch (error) {
      console.error(`Error fetching description: ${error.message}`);
      setUseApiContent(false);
      setDescription(selectedNode.desc || 'No description available');
    }
  }

  async function checkEmbeddable(url) {
    try {
      const response = await axios.get(`${API_URL}/check-embeddable?url=${encodeURIComponent(url)}`);
      console.log('response.data.embeddable: ', response.data.embeddable);
      const isGithubUrl = url.includes("github.com");
      setIframeError(!response.data.embeddable || isGithubUrl);
      setEmbeddable(response.data.embeddable);
    } catch (error) {
      console.error('Error checking embeddability:', error);
      setEmbeddable(false);
      setIframeError(true);
    }
  }

  useEffect(() => {
    if (selectedField === 'url' && selectedNode?.url) {
      setIframeUrl(selectedNode.url);
      checkEmbeddable(selectedNode.url);
    }
  }, [selectedField, selectedNode]);

  const renderContent = () => {
    if (!selectedNode) return <div>Select a node to see details</div>;

    const descValue = selectedNode.desc;

    switch (selectedField) {
      case 'desc':
        return useApiContent ? (
          <iframe
            srcDoc={apiContent}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Content"
          />
        ) : (
          <div style={{ width: '100%', height: '100%' }}>{descValue}</div>
        );

      case 'url':
        return (
          <div style={{ position: 'relative', width: '100%', height: '100%', textAlign: 'center' }}>
            {iframeError ? (
              <div>
                <h2>{selectedNode.name}</h2>
                <button
                  onClick={() => openInNewTab(iframeUrl)}
                  style={{
                    display: 'inline-block',
                    margin: '20px auto',
                    padding: '10px 20px',
                    fontSize: '16px',
                    color: '#007bff',
                    backgroundColor: 'white',
                    border: '2px solid #007bff',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    textDecoration: 'none'
                  }}
                >
                  Visit {selectedNode.name} {treeType}
                </button>
              </div>
            ) : (
              <iframe
                src={iframeUrl}
                style={{ width: '100%', height: '100%', border: 'none' }}
                onLoad={() => console.log('Iframe loaded successfully')}
                onError={() => setIframeError(true)}
              />
            )}
          </div>
        );

      case 'team':
        return <div>{selectedNode.team}</div>;

      default:
        return null;
    }
  };

  return (
    <Box style={{ backgroundColor: 'lightgrey', border: '1px solid white', padding: '5px', marginTop: '10px', marginBottom: '20px', height: '100%' }}>
      <Box style={{ marginBottom: '7px' }}>
        <label>
          <input type="radio" value="desc" checked={selectedField === 'desc'} onChange={() => setSelectedField('desc')} />
          Description
        </label>
        <label>
          <input type="radio" value="url" checked={selectedField === 'url'} onChange={() => setSelectedField('url')} />
          URL
        </label>
        <button onClick={() => openInNewTab(selectedNode?.url)} style={{ marginLeft: '10px' }}>
          Open in own tab
        </button>
        <label>
          <input type="radio" value="team" checked={selectedField === 'team'} onChange={() => setSelectedField('team')} />
          Team
        </label>
      </Box>
      <Box style={{
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '0px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        height: 'calc(80%)',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center', // Zentriert den Inhalt horizontal
        alignItems: 'top' // Zentriert den Inhalt vertikal
      }}>
        {renderContent()}
      </Box>
    </Box>
  );
};

export default SectionRendererDetails;

/* treebeardStyles.js */
export default {
  tree: {
    base: {
      listStyle: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      margin: 0,
      padding: 0,
      color: '#333',
      fontFamily: 'Lato, sans-serif',
      fontSize: '14px'
    },
    node: {
      base: {
        position: 'relative',
        padding: '5px 0',
      },
      link: {
        cursor: 'pointer',
        position: 'relative',
        padding: '0px 5px',
        display: 'block'
      },
      activeLink: {
        background: '#dcdcdc'
      },
      toggle: {
        base: {
          position: 'relative',
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '-5px',
          height: '24px',
          width: '24px',
          // Hide the built-in toggle icon
          display: 'none'
        },
        wrapper: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          margin: '-7px 0 0 -7px',
          height: '14px'
        },
        height: 14,
        width: 14,
        arrow: {
          fill: '#9DA5AB',
          strokeWidth: 0
        }
      },
      header: {
        base: {
          display: 'inline-block',
          verticalAlign: 'middle',
          color: '#333'
        },
        connector: {
          width: '2px',
          height: '12px',
          borderLeft: 'solid 2px black',
          borderBottom: 'solid 2px black',
          position: 'absolute',
          top: '0px',
          left: '-21px'
        },
        title: {
          lineHeight: '24px',
          verticalAlign: 'middle'
        }
      },
      subtree: {
        listStyle: 'none',
        paddingLeft: '19px'
      },
      loading: {
        color: '#E2C089'
      }
    }
  }
};

import React from 'react';
import { Box, Grid } from '@mui/material';

const logos = [
  {
    name: 'Linux',
    src: 'https://upload.wikimedia.org/wikipedia/commons/3/35/Tux.svg',
    alt: ''
  },
  {
    name: 'Apache',
    src: 'https://upload.wikimedia.org/wikipedia/commons/6/62/Apache_HTTP_server_logo.svg',
    alt: ''
  },
  {
    name: 'Firefox',
    src: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Firefox_logo%2C_2019.svg',
    alt: ''
  },
  {
    name: 'Kubernetes',
    src: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Kubernetes_logo_without_workmark.svg',
    alt: ''
  },
  {
    name: 'MySQL',
    src: '/mysql.svg',
    alt: ''
  },
  {
    name: 'Docker',
    src: '/docker.svg',
    alt: ''
  },
  {
    name: 'Node.js',
    src: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg',
    alt: ''
  },
  {
    name: 'Python',
    src: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg',
    alt: ''
  },
  {
    name: 'Ubuntu',
    src: 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo-ubuntu_cof-orange-hex.svg',
    alt: ''
  },
  {
    name: 'LibreOffice',
    src: 'https://upload.wikimedia.org/wikipedia/commons/4/46/LibreOffice_logo_initials_only_2013.svg',
    alt: ''
  }
];

const Advertisement = () => {
  return (
    <Box style={{ height: '300px', backgroundColor: 'lightblue', padding: '20px' }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        {logos.map(logo => (
          <Grid item key={logo.name}>
            <img src={logo.src} alt={logo.alt} style={{ height: '80px', margin: '0 10px' }} /> {/* Increased height by 20% */}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Advertisement;

import React, { useRef } from 'react';
import ProjectsIcon from '@mui/icons-material/Folder';
import LicensesIcon from '@mui/icons-material/Assignment';
import DocumentationIcon from '@mui/icons-material/Description';
import ToolsIcon from '@mui/icons-material/Build';
import TutorialsIcon from '@mui/icons-material/School';
import SourcesIcon from '@mui/icons-material/Source';
import ForumsIcon from '@mui/icons-material/Forum';

// console.log("NodeHeader component loaded");

const NodeHeader = ({ node, onToggle, treeType }) => {
  const { name, url, desc, team, children } = node;
  const clickTimeout = useRef(null);

  const handleSingleClick = (e) => {
    // console.log('Single click detected on node:', node);
    if (onToggle) {
      onToggle(node, !node.toggled);
    } else {
      console.error('onToggle is not a function');
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    clearTimeout(clickTimeout.current);
    clickTimeout.current = setTimeout(() => {
      handleSingleClick(e);
    }, 300);
  };

  const getIconByTreeType = (type) => {
    console.log('getIconByTreeType called with type:', type);
    switch (type) {
      case 'projects':
        return <ProjectsIcon style={{ marginRight: '5px' }} />;
      case 'licenses':
        return <LicensesIcon style={{ marginRight: '5px' }} />;
      case 'documentation':
        return <DocumentationIcon style={{ marginRight: '5px' }} />;
      case 'tools':
        return <ToolsIcon style={{ marginRight: '5px' }} />;
      case 'tutorials':
        return <TutorialsIcon style={{ marginRight: '5px' }} />;
      case 'sources':
        return <SourcesIcon style={{ marginRight: '5px' }} />;
      case 'forums':
        return <ForumsIcon style={{ marginRight: '5px' }} />;
      default:
        return null;
    }
  };

  //console.log('Rendering NodeHeader with treeType:', treeType);
  //console.log('Node name:', name);

  const isRoot = name.toLowerCase() === treeType.toLowerCase();
  // console.log('Is root:', isRoot);

  const rootStyle = {
    fontSize: '18px', // Adjust the font size as needed
    fontWeight: 'bold',
  };

  return (
    <div onClick={handleClick} style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
      {isRoot && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {getIconByTreeType(treeType)}
            <span style={rootStyle}>{name}</span>
          </div>
        </>
      )}
      {!isRoot && (
        <span style={{ display: 'inline-flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {children && children.length === 0 ? (
              <svg height="10" width="10" style={{ marginRight: '5px' }}>
                <circle cx="5" cy="5" r="3" stroke="black" strokeWidth="1" fill="none" />
              </svg>
            ) : (
              <span style={{ marginRight: '5px' }}>&#9654;</span> // Unicode for the arrow
            )}
            <span>{name}</span>
          </div>
        </span>
      )}
    </div>
  );
};

export default NodeHeader;

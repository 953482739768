import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const NoticeDialog = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="notice-dialog-title"
      aria-describedby="notice-dialog-description"
    >
      <DialogTitle id="notice-dialog-title">Notice</DialogTitle>
      <DialogContent>
        <DialogContentText id="notice-dialog-description">
          This site is still under development and is continuously being improved. It will only be fully usable from version 1.0.0.
          <br /><br />
          Planned Milestones:
          <br />
          Version 0.1.0: All selectable elements are linked to a function
          <br />
          Version 0.2.0: The explorer can already be used well in some areas.
          <br /><br />
          We appreciate all suggestions and support. Please help us improve productivity and the overall experience.
          <br />
          Contact us at: <a href="mailto:admin@open-source.org">admin@open-source.org</a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoticeDialog;

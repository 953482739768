import React, { useEffect } from 'react';

const TestPage = () => {
  useEffect(() => {
    console.log('Angular component loaded');
  }, []);

  return (
    <div>
      <h1>Test Page</h1>
      <app-section-detail-table></app-section-detail-table>
    </div>
  );
};

export default TestPage;
